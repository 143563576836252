import Rails from "@rails/ujs";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

window.jQuery = window.$ = require("jquery");

window.Rails = Rails;

import("src/plugins"); // loads async
import "bootstrap";
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/all";
